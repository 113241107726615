import { Box, Grid, LinearProgress, Typography, useTheme } from '@mui/material';
import {
    SuspenseWithPerf, useFirestore,
    useFirestoreCollectionData, useFirestoreDocData
} from 'reactfire'
import ResultsHeader from './results-header';
import ResultsEntries from './results-riders';
import { useWakeLock } from 'react-screen-wake-lock';
import { useEffect } from 'react';
import ResultsFooter from './results-footer';
import {
    collection, CollectionReference, doc,
    DocumentReference, orderBy, query, Timestamp
} from 'firebase/firestore';
import { useMediaQuery } from 'react-responsive';

// import here and then they are part of the tree for child components
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './grid-styles.css';


export type TEvent = {
    $id: string;
    course: string;
    date: string,
    name: string;
    provisional: boolean;
    bgcolor?: string;
    color?: string;
    logo?: string;
    categories: Record<string, TCategory[]>

    showTopXInCategory?: number;
    columns?: number;
    rows?: number;
    splits?: number;
    calculateHandicap?: boolean;
    calculateVtta?: boolean;
    displayCategoryPosition?: boolean;

};

export type TCategory = {
    club: string;
    name: string;
    number: number;
    position: number;
    time: string;
};


export type TEntry = {
    $id: string;
    pb: string;
    club: string;
    category: string;
    name: string;
    handicap: string | null;
    aat: string | null;
    number: number;
    eccaCategory: string;
    startTime: string;
    time: string;
    overallPosition: string | null;

    handicapPosition: string | null;
    handicapTime: string | null;

    vttaPosition: string | null;
    vttaAat: string | null;
    vttaAatTime: string | null;
};

export type time = {
    startTime: string;
    endTime: string;
    splitTimes: Array<splitTime>;

    /** Time in seconds */
    duration: number;

};

export type splitTime = {
    time: Timestamp;
    name: string;
}


function ResultsBoard() {

    const { request: setWakeLock } = useWakeLock();

    useEffect(() => {
        setWakeLock()
    });


    console.log(window.location.pathname);
    const eventId = window.location.pathname.replace(/^\//, ''); // 'ld2dxrfOlpupP02JoLj3';
    console.log(`eventId:${eventId}`);

    const firestore = useFirestore();

    const entriesRef = collection(firestore, 'events', eventId, 'entries') as CollectionReference<TEntry>;
    const entriesQuery = query(entriesRef, orderBy('number'));
    const { data: entriesData } = useFirestoreCollectionData<TEntry>(entriesQuery, { idField: '$id' });

    const docRef = doc(firestore, 'events', eventId) as DocumentReference<TEvent>;
    const { data: eventDocData } = useFirestoreDocData<TEvent>(docRef, { idField: '$id' });

    const theme = useTheme();

    // Use a single column on < lg
    const singleColumn = useMediaQuery({ query: `(max-width: ${theme.breakpoints.values.lg}px)` });

    return (

        <SuspenseWithPerf fallback={<LinearProgress />} traceId={'loadEventDoc'}>

            {!eventDocData && (
                <Box width={'100vw'} height={'100vh'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    <Typography m={'auto'} variant='h1'>
                        Invalid Event
                    </Typography>
                </Box>
            )}

            {eventDocData && (

                <Grid container direction={'column'} minHeight={'100vh'}
                    bgcolor={eventDocData.bgcolor || '#e4002b'} rowGap={2}>

                    <ResultsHeader docData={eventDocData} />

                    <Grid item lg>
                        <ResultsEntries
                            entriesData={entriesData}
                            event={eventDocData}
                            splits={eventDocData.splits ?? 0}
                            singleColumn={singleColumn} />
                    </Grid>

                    <Grid item pb={1}>
                        <ResultsFooter
                            docData={eventDocData}
                            displayRows={singleColumn ?
                                (Object.keys(eventDocData.categories ?? {})?.length ?? 1) :
                                (eventDocData.rows ?? 1)} />
                    </Grid>

                </Grid>
            )}

        </SuspenseWithPerf>

    )
}

export default ResultsBoard
