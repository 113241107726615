import { LinearProgress } from "@mui/material";
import { useEffect } from "react";
import { SuspenseWithPerf, useSigninCheck } from "reactfire";



export const AuthWrapper = ({ children, fallback }: React.PropsWithChildren<{ fallback: JSX.Element }>): JSX.Element => {

    const { data: signInCheckResult } = useSigninCheck();

    console.debug('AuthWrapper');

    useEffect(() => {
        console.debug('user', signInCheckResult.user);
    }, [signInCheckResult.user]);


    if (!children) {
        throw new Error('Children must be provided');
    }

    return (
        <SuspenseWithPerf fallback={<LinearProgress />} traceId={'AuthWrapper'}>
            {signInCheckResult.signedIn === true ? children : fallback}
        </SuspenseWithPerf>
    );

};
