import { LinearProgress } from '@mui/material'
import { signInAnonymously } from 'firebase/auth';
import React, { useEffect } from 'react'
import { useAuth } from 'reactfire';


function SignIn() {

    const auth = useAuth();

    // Sign in once
    useEffect(() => {
        signInAnonymously(auth);
    }, [auth]);

    return <LinearProgress />;

}

export default SignIn
