import { AuthProvider, FirestoreProvider, FunctionsProvider, useInitAuth, useInitFirestore } from 'reactfire';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { CACHE_SIZE_UNLIMITED, connectFirestoreEmulator, getFirestore, initializeFirestore } from 'firebase/firestore';
import { AuthWrapper } from './auth-wrapper';
import SignIn from './signin';
import ResultsBoard from './results-board';
import { getFunctions } from 'firebase/functions';

function App() {

    return (
        <AuthProviderWrapper>
            <AuthWrapper fallback={<SignIn />}>
                <FirestoreWrapper>
                    <ResultsBoard />
                </FirestoreWrapper>
            </AuthWrapper>
        </AuthProviderWrapper>
    );

}

function AuthProviderWrapper({ children }: React.PropsWithChildren) {

    // console.log('AuthWrapper');

    const { data: auth, status, error } = useInitAuth(async (app) => {

        const authInstance = getAuth(app);

        try {
            if (process.env.NODE_ENV === 'development') connectAuthEmulator(authInstance, 'http://localhost:9099');
        } catch (error) {
            console.warn(error);
        }

        return authInstance;

    });

    if (status === 'loading') return <>Loading Auth...</>;

    return (
        <AuthProvider sdk={auth}>
            {children}
        </AuthProvider>
    );

}


/**
* Wrapper for firestore init, sets firestore configuration
* @param param0
* @returns
*/
function FirestoreWrapper({ children }: React.PropsWithChildren) {

    // console.log('FirestoreWrapper');

    const { data: firestoreInstance, status } = useInitFirestore(async (firebaseApp) => {

        // console.log('useInitFirestore');

        const firestore = initializeFirestore(firebaseApp, {
            cacheSizeBytes: CACHE_SIZE_UNLIMITED
        });

        try {
            if (process.env.NODE_ENV === 'development') connectFirestoreEmulator(firestore, 'localhost', 8080);
        } catch (error) {
            console.warn('connectFirestoreEmulator:ERROR');
        }

        return firestore;

    }, { suspense: false });

    if (status === 'loading') return <>Loading Firestore...</>;

    return (
        <FirestoreProvider sdk={firestoreInstance}>
            {children}
        </FirestoreProvider>
    );

}


export default App;
