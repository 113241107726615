import { Typography, Grid } from '@mui/material';
import { DateTime } from 'luxon';
import { TEvent } from './results-board';


function ResultsHeader({ docData }: { docData: TEvent }) {

    const logoUrl = 'https://www.shaftesbury.cc/_next/image?url=%2Fassets%2Flogo.webp&w=256&q=75';

    return (

        <Grid container direction={'row'} p={2} alignItems={'center'}>

            <Grid item xs={12} lg textAlign={{
                xs: 'center',
                lg: 'left',
            }}>
                {<img src={docData.logo ?? logoUrl} alt={'logo'} style={{ maxWidth: '100%' }} />}
            </Grid>

            <Grid item xs={12} lg={8}>

                <Typography variant='h3' color={docData.color ?? 'white'} textAlign={'center'}>
                    {docData.name}
                </Typography>

                <Typography variant='h5' color={docData.color ?? 'white'} textAlign={'center'}>
                    {docData.course}
                </Typography>

                <Typography variant='h5' color={docData.color ?? 'white'} textAlign={'center'}>
                    {DateTime.fromISO(docData.date).toLocaleString(DateTime.DATE_MED)}
                </Typography>

                {docData.provisional && (
                    <Typography variant='h5' color={docData.color ?? 'white'} textAlign={'center'}>
                        PROVISONAL RESULTS
                    </Typography>
                )}

            </Grid>

            <Grid item xs={12} lg display={{ xs: 'none', lg: 'inline' }} textAlign={'right'}>
                {<img src={docData.logo ?? logoUrl} alt={'logo'} style={{ maxWidth: '100%' }} />}
            </Grid>

        </Grid>

    );
}

export default ResultsHeader;
