import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { FirebaseAppProvider } from 'reactfire';
import { Button, CssBaseline, Stack } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCXJhu69SurrdWfWelyb16BaqEL4tRcaFI",
  authDomain: "shaftesbury-cycling-club.firebaseapp.com",
  databaseURL: "https://shaftesbury-cycling-club-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "shaftesbury-cycling-club",
  storageBucket: "shaftesbury-cycling-club.appspot.com",
  messagingSenderId: "61082232775",
  appId: "1:61082232775:web:04c60100b8133a5e0a9be2",
  measurementId: "G-QJCFY0YNSQ"
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

function ErrorFallback({ error }: { error: Error }) {

  const handleReload = () => window?.location?.reload();

  return (
    <Stack>
      <pre>{error.message}</pre>

      <Button onClick={handleReload} variant='contained' size='large'>
        Restart
      </Button>

    </Stack>
  );
}

root.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <FirebaseAppProvider firebaseConfig={firebaseConfig} suspense={true}>
        <CssBaseline />
        <App />
      </FirebaseAppProvider>
    </ErrorBoundary>
  </React.StrictMode>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
