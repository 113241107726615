import { Grid, Paper } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { useMemo } from 'react';
import { chunk, ceil } from 'lodash';
import { TEntry, TEvent } from './results-board';
import { ColDef, GroupCellRendererParams } from 'ag-grid-community';



const columnDefs = (
    splits = 0, displayVtta = true,
    displayHandicap = true,
    displayCategoryPosition = false
): Array<ColDef<TEntry>> => [
        {

            field: 'number',
            headerName: 'No.',
            width: 30,
            cellClass: 'centered-cell',
            headerClass: 'align-center',
            pinned: true,
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            minWidth: 120,
            autoHeight: true,
            cellStyle: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
            cellRenderer: (params: GroupCellRendererParams<TEntry, string>) => {
                return (
                    <Grid container direction={'column'} rowGap={0.5} width={'100%'}>
                        <Grid item lineHeight={1} fontWeight={'600'} width={'100%'}>
                            {params.value}
                        </Grid>
                        <Grid item lineHeight={1} color={t => t.palette.grey[600]} fontSize={'0.8rem'} width={'100%'}>
                            {params.data?.club}
                        </Grid>
                    </Grid>
                );
            }
        },
        ...(splits > 0 ?

            new Array(splits).fill(null).map((value, index) => {

                return {
                    field: `split${index + 1}`,
                    headerName: `Split ${index + 1}`,
                    width: 80,
                    cellClass: 'centered-cell',
                    headerClass: 'align-center',
                    enableCellChangeFlash: true,
                };

            })

            : []

        ),

        {
            field: 'time',
            headerName: 'Finish',
            width: 80,
            cellClass: 'centered-cell',
            headerClass: 'align-center',
            enableCellChangeFlash: true,
        },
        {
            headerName: 'Overall',
            field: 'overallPosition',
            cellClass: 'centered-cell',
            headerClass: 'align-center',
            width: 80,
            enableCellChangeFlash: true,
            cellRenderer: (params: GroupCellRendererParams) => {

                if (params.value === 1) {
                    return '1 🥇';
                }

                if (params.value === 2) {
                    return '2 🥈';
                }

                if (params.value === 3) {
                    return '3 🥉';
                }

                return params.value;

            },
        },

        {
            headerName: 'Category',
            field: 'categoryPosition',
            cellClass: 'centered-cell',
            headerClass: 'align-center',
            width: 80,
            enableCellChangeFlash: true,
            hide: displayCategoryPosition != true,
            cellRenderer: (params: GroupCellRendererParams) => {

                if (params.value === 1) {
                    return '1 🥇';
                }

                if (params.value === 2) {
                    return '2 🥈';
                }

                if (params.value === 3) {
                    return '3 🥉';
                }

                return params.value;

            },

        },

        {
            headerName: 'Handicap',
            field: 'handicapPosition',
            cellClass: 'centered-cell',
            headerClass: 'align-center',
            width: 80,
            enableCellChangeFlash: true,
            hide: displayHandicap != true,
            cellRenderer: (params: GroupCellRendererParams) => {

                if (params.value === 1) {
                    return '1 🥇';
                }

                if (params.value === 2) {
                    return '2 🥈';
                }

                if (params.value === 3) {
                    return '3 🥉';
                }

                return params.value;

            },
        },
        {
            headerName: 'AAT',
            field: 'vttaPosition',
            cellClass: 'centered-cell',
            headerClass: 'align-center',
            width: 80,
            enableCellChangeFlash: true,
            hide: displayVtta != true,
            cellRenderer: (params: GroupCellRendererParams) => {

                if (params.value === 1) {
                    return '1 🥇';
                }

                if (params.value === 2) {
                    return '2 🥈';
                }

                if (params.value === 3) {
                    return '3 🥉';
                }

                return params.value;

            },
        },

    ];

type TResultsEntries = {
    entriesData: TEntry[];
    splits: number;
    event: TEvent;
    singleColumn: boolean;
};

function ResultsEntries({ entriesData, splits, event, singleColumn }: TResultsEntries) {

    const className = 'ag-theme-alpine';

    // Split into columns
    const chunks = useMemo(() => {
        return chunk(entriesData, singleColumn ? entriesData.length : ceil(entriesData.length / (event.columns ?? 2)));
    }, [entriesData, event.columns, singleColumn]);

    const columnDefsWithSplits = useMemo(() => {

        return columnDefs(
            splits, event.calculateVtta === true,
            event.calculateHandicap === true,
            event.displayCategoryPosition);

    }, [
        splits,
        event.calculateVtta,
        event.calculateHandicap,
        event.displayCategoryPosition
    ]);

    return (

        <Grid container direction={'row'} columnGap={2} pl={2} pr={2} height={{ lg: '100%' }}>

            {chunks.map((chunk, index) => (

                <Grid item xs={12} lg key={index}
                    component={Paper}
                    variant={'outlined'}
                    className={className} mb={{ xs: 2, lg: 0 }} height={{ lg: '100%' }}>

                    <AgGridReact
                        suppressCellFocus={true}
                        suppressRowHoverHighlight={true}
                        suppressMovableColumns={true}
                        domLayout={'autoHeight'}
                        animateRows={false}
                        enableCellChangeFlash={true}
                        cellFadeDelay={1000}
                        cellFlashDelay={3000}
                        rowData={chunk}
                        columnDefs={columnDefsWithSplits}
                        getRowId={({ data }) => data.$id}
                    />

                </Grid>

            ))}

        </Grid>

    )

}

export default ResultsEntries
