import { Grid, Paper, Typography } from '@mui/material';
import { ColDef, GroupCellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { ceil, chain, size } from 'lodash';
import { useMemo } from 'react';
import { TCategory, TEvent } from './results-board';

const columnDefs: ColDef<TCategory>[] = [
    {
        field: 'position',
        headerName: '#',
        width: 30,
        cellClass: 'centered-cell',
        headerClass: 'align-center',
        enableCellChangeFlash: true,
    },
    {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        enableCellChangeFlash: true,
        autoHeight: true,
        wrapText: true,
        cellRenderer: (params: GroupCellRendererParams<TCategory, string>) => {
            return (
                <Grid container direction={'column'} rowGap={0.5} maxWidth={'100%'}>
                    <Grid item
                        lineHeight={1} fontWeight={'600'}
                        maxWidth={'100% !important'}
                        overflow={'hidden'} whiteSpace={'nowrap'}
                        textOverflow={'ellipsis'}>
                        {params.value}
                    </Grid>
                    <Grid item lineHeight={1} color={t => t.palette.grey[600]}
                        fontSize={'0.8rem'} maxWidth={'100% !important'}
                        overflow={'hidden'} whiteSpace={'nowrap'}
                        textOverflow={'ellipsis'}>
                        {params.data?.club ?? '-'}
                    </Grid>
                </Grid>
            );
        }
    },
    {
        headerName: 'Time',
        field: 'time',
        width: 100,
        enableCellChangeFlash: true,
        cellClass: 'centered-cell',
        headerClass: 'align-center',
    },

];



function ResultsFooter({ docData, displayRows = 1 }: { docData: TEvent, displayRows: number }) {

    const className = 'ag-theme-alpine';

    const rows = useMemo(() => {

        const categories = chain(docData.categories)
            .toPairs()
            .filter(([, data]) => data !== null)
            .sortBy((data) => data[0])
            .chunk(ceil(size(docData.categories) / displayRows))
            .value();

        // console.log({ categories });

        return categories;

    }, [docData.categories, [displayRows]]);

    return (

        <>

            <Typography variant='h4' textAlign={'center'} color={docData.color ?? 'white'}>
                Leaderboard
            </Typography>

            <Grid container direction={'column'} gap={2} pl={2} pr={2}>

                {rows.map((category, index) => (

                    <Grid key={index} item container
                        direction={{
                            xs: 'column',
                            lg: 'row',
                        }}
                        gap={2}>

                        {category.map(([id, values]) => (

                            <Grid
                                key={id}
                                item
                                xs
                                className={className}
                                component={Paper}
                                variant={'outlined'}>

                                <Typography variant='h5' textAlign={'center'} mt={1}>
                                    {id}
                                </Typography>

                                <AgGridReact
                                    domLayout={'autoHeight'}
                                    suppressMovableColumns={true}
                                    suppressRowHoverHighlight={true}
                                    animateRows={true}
                                    enableCellChangeFlash={true}
                                    cellFadeDelay={1000}
                                    cellFlashDelay={3000}
                                    columnDefs={columnDefs}
                                    rowData={values.slice(0, docData.showTopXInCategory)}
                                    getRowId={({ data }) => `${data.name}-${data.club}`} />

                            </Grid>

                        ))}

                    </Grid>

                ))}

            </Grid>

            <Typography variant='h6' textAlign={'center'} color={docData.color ?? 'white'}>
                Results by TimeTrialHQ.com
            </Typography>

        </>

    );

}

export default ResultsFooter;
